import React from 'react'
import { Box, Flex, Heading } from '@chakra-ui/react'

export const DictionaryDefinition = ({ word, type, pronunciation, children }) => (
    <Flex flexDirection="column" gap={2} px={{ base: 4, md: 0 }} fontFamily="serif">
        <Flex as="header" gap={2} flexWrap="wrap" alignItems="baseline">
            <Heading as="h3" display="inline-block" mb={0}>
                {word}
            </Heading>
            <Box as="span" fontSize="xl" color="oriTeal.300" fontWeight={600}>
                {type}
            </Box>
        </Flex>
        <Box as="main">
            <Box fontSize="2xl" color="oriTeal.300" mt={-2} mb={2}>
                {pronunciation}
            </Box>
            <Box>{children}</Box>
        </Box>
    </Flex>
)
