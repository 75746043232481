import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { Link as ChakraLink } from '@chakra-ui/react'

export const Link = ({ children, destination, ...additionalProps }) => {
    let isExternal = false

    const cleanDestination = destination.replace(/^\/{2,}/, '/')

    if (cleanDestination.match(/^(https?:\/\/|mailto:|tel:)/)) {
        isExternal = true
    }

    return (
        <ChakraLink
            {...(isExternal
                ? {
                      href: cleanDestination,
                      target: '_blank',
                      rel: 'noreferrer',
                  }
                : {
                      as: GatsbyLink,
                      to: cleanDestination,
                  })}
            {...additionalProps}
        >
            {children}
        </ChakraLink>
    )
}
